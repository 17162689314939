var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"card-custom",attrs:{"no-body":"","header-tag":"header","footer-tag":"footer"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("MENU.ITEM.TANKS.GPS_TANKS"))+" "),_c('i',{staticClass:"mr-2"}),_c('small',{},[_vm._v(_vm._s(_vm.$t("LABELS.LIST")))])])])]},proxy:true}])},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPageLoading),expression:"!isPageLoading"}]},[_c('SearchTools',{attrs:{"searchQuery":_vm.searchQuery},on:{"update:searchQuery":function($event){_vm.searchQuery=$event},"update:search-query":function($event){_vm.searchQuery=$event},"do-search":_vm.doSearch,"do-clear":_vm.doClear}}),_c('v-data-table',{staticClass:"px-6 elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"options":_vm.options,"items-per-page":_vm.totalItems,"server-items-length":_vm.totalItems,"loading":_vm.isLoadingGpsTank,"loading-text":"Loading... Please wait","footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: _vm.rowsPerPageItems,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
        }},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.getDataFromApi()}]},scopedSlots:_vm._u([{key:"item.gasTankStationId",fn:function(ref){
        var item = ref.item;
return [_c('FormSelect',{attrs:{"options":_vm.gasStation,"clearable":"true","item-text":item.gasStationName,"item-value":"id","appendToBody":"true"},on:{"input":function (id) { return _vm.updateGpsGasTankStationId(id, item.gpsGasTankStationId); }},model:{value:(item.gasStationId),callback:function ($$v) {_vm.$set(item, "gasStationId", $$v)},expression:"item.gasStationId"}})]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" Your search for \""+_vm._s(_vm.searchQuery)+"\" found no results. ")])]},proxy:true}],null,true)})],1),(_vm.isPageLoading)?_c('v-skeleton-loader',{attrs:{"loading":_vm.isPageLoading,"type":"table"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }