<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.TANKS.GPS_TANKS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingGpsTank"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
          }"
          @update:options="getDataFromApi()"
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.gasTankStationId`]="{ item }">
            <FormSelect
              :options="gasStation"
              v-model="item.gasStationId"
              clearable="true"
              :item-text="item.gasStationName"
              item-value="id"
              @input="(id) => updateGpsGasTankStationId(id, item.gpsGasTankStationId)"
              appendToBody="true"
            />
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import SearchTools from "@/core/components/table/SearchTools.vue";

import { FETCH_GPS_TANKS } from "@/modules/gps-tanks/store/gpstanks.module";

import {
  FETCH_TANKS,
  UPDATE_GAS_TANK_STATION_ID,
} from "@/modules/tanks/store/tanks.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

import FormSelect from "@/view/content/forms/components/FormSelect.vue";

export default {
  mixins: [permissionMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      totalItems: 100,
      searchQuery: "",
      gasStation: [],
      options: {
        descending: true,
        page: 1,
        itemsPerPage: 30,
        totalItems: 100,
      },
      filters: {},
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: this.$i18n.t("FORM_LABELS.GPS_TRACKER_NUMBER"),
          value: "gpsGasTankStationId",
        },
        { text: this.$i18n.t("FORM_LABELS.TANK_NAME"), value: "name" },
        { text: this.$i18n.t("FORM_LABELS.DESCRIPTION"), value: "description" },
        {
          text: this.$i18n.t("FORM_LABELS.MAX_CAPACITY"),
          value: "maxCapacity",
        },
        {
          text: this.$i18n.t("FORM_LABELS.TANK"),
          value: "gasTankStationId",
          width: "20%",
        },
        { text: this.$i18n.t("FORM_LABELS.FIRM_NAME"), value: "firmName" },
      ],
    };
  },
  components: {
    SearchTools,
    FormSelect,
  },
  watch: {},
  async mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.TANKS.GPS_TANKS"),
        route: { name: "list-gps-tanks" },
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") },
    ]);

    this.fetchTanks();
  },
  computed: {
    ...mapGetters(["getGpsTank", "isLoadingGpsTank"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    },
  },
  methods: {
    async fetchTanks() {
      await this.$store.dispatch(FETCH_TANKS).then((data) => {
        this.gasStation = data.data.items.map((item) => {
          return {
            ...item,
            selectable: item.gpsGasTankStationId == "",
          };
        });
      });
    },
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start = params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      vm.$store
        .dispatch(FETCH_GPS_TANKS, apiParams)
        .then((data) => {
          vm.$nextTick(function () {
            vm.items = data.data.items;

            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found",
            });
            this.$router.push({ name: "list-gps-tanks" });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    async updateGpsGasTankStationId(id, gpsGasTankStationId) {
      await this.$store
        .dispatch(UPDATE_GAS_TANK_STATION_ID, { id, gpsGasTankStationId })
        .then(() => {
          this.$notify({
            group: "notify",
            type: "success",
            title: this.$t("ALERTS.SUCCESS"),
            text: "Успешно сменен",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
      this.fetchTanks();
    },
  },
};
</script>
